<template>
    <div>
        <div v-for="(document, index) in row.item.sent_documents" :key="document.id">
            <template v-if="index <= 2">
                <a @click.prevent="$root.openDocument(document.name)"
                   :href="$root.getDocumentUrl(document.name)"
                >{{ document.name }}</a>
                <span v-if="document.sent_at">
                    {{ document.sent_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                </span>
                <span v-if="document.user_name">
                    {{ document.user_name }}
                </span>
                <template v-if="document.message_id">
                    <b-button
                        @click="$root.$children[0].openModal('show-email-message-modal', {messageId: document.message_id}, null, {width: '800px'})"
                        size="sm"
                        variant="info"
                        class="mr-2"
                    >
                        <font-awesome-icon icon="eye"/>
                    </b-button>
                </template>
            </template>
        </div>

        <template v-if="row.item.sent_documents.length > 3">
            <b-card-header header-tag="header" class="p-0 mt-1" role="tab">
                <b-button block v-b-toggle="'accordion-' + row.item.id" class="p-0" variant="info">
                    {{ $t('common.label.more') }}
                </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-'+row.item.id" accordion="my-accordion"
                        role="tabpanel">
                <div v-for="(document, index) in row.item.sent_documents" :key="document.id">
                    <template v-if="index > 3">
                        <a @click.prevent="$root.openDocument(document.name)"
                           :href="$root.getDocumentUrl(document.name)"
                        >{{ document.name }}</a>
                        <span v-if="document.sent_at">
                            {{ document.sent_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                        </span>
                        <span v-if="document.user_name">
                            {{ document.user_name }}
                        </span>
                        <template v-if="document.message_id">
                            <b-button
                                @click="$root.$children[0].openModal('show-email-message-modal', {messageId: document.message_id}, null, {width: '800px'})"
                                size="sm"
                                variant="info"
                                class="mr-2"
                            >
                                <font-awesome-icon icon="eye"/>
                            </b-button>
                        </template>
                    </template>
                </div>
            </b-collapse>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>